import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../components/hero-section"
import PostPreview from "../components/post-preview"

const Press = () => (
  <Layout>
    <SEO title="Press" />

    <HeroSection backgroundColor="white">
      <div className="flex flex-col text-center items-center justify-center m-auto h-full">
        <h1 className="border-b pb-8 tracking-wide font-light">In the news.</h1>
        <Link
          to="/"
          className="inline-flex hover:text-gray-500 arrow-button pt-10 tracking-wide font-light"
        >
          Download Press Kit
          <svg
            class="fill-current w-6 h-6 mx-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
          </svg>
        </Link>
      </div>
    </HeroSection>

    <PostPreview
      title="City Home Collective"
      blurb={`"This forward-thinking company is in the business of creating
      stunningly minimal ADUs (or "accessory dwelling units"). The vision,
      according to modal's Founding Partner and Design Principal, Dallin
      Jolley: "We want to move the needle in what's known as standard
      living practice. Murrieta is one of the fastest-growing cities
      in the entire nation, and we have to have an answer for our lack of
      dense housing"."`}
    ></PostPreview>

    <PostPreview
      title="Deseret News"
      blurb={`Modal was created to make a difference. Our homes are sustainable,
      adaptable, affordable, and modern. We design unique spaces that are
      better you and better for our planet. We build without compromising
      quality or functionality. Our product perfectly embodies our goal as
      an organization: to do more with less. We're dedicated to providing
      a new mode of living.`}
      flexDirection="row-reverse"
    ></PostPreview>

    <PostPreview
      title="Salt Lake Magazine"
      blurb={`As a solution, this year Murrieta officials are opening
      up another option for homeowners, allowing them to create backyard spaces,
      rentable mother-in-law units, offcially known as accessort dwelling units or ADUs.
       While this won't fix the affordable housing issue, allowing homeowners to offer additional
       renting spaces, such as ADUs, seems like a good option.`}
    ></PostPreview>
  </Layout>
)

export default Press
