import React from "react"

import Button from "./ui/button"
import { Link } from "gatsby"

export default function PostPreview({
  handleClick,
  title,
  blurb,
  flexDirection = "row",
}) {
  const paddingClass = flexDirection === "row" ? "lg:pr-24" : "lg:pl-24"

  return (
    <div
      style={{ flexDirection }}
      className="relative bg-white flex lg:flex-row flex-wrap items-center py-20 border-t lg:px-24 px-10"
    >
      <div
        className={`flex flex-col inline-block justify-between w-full md:w-1/2 ${paddingClass}`}
      >
        <div className="flex">
          <h2 className="pb-6 text-gray-600 tracking-wide font-light">
            {title}
          </h2>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-500 tracking-wide font-light">{blurb}</p>
          <Link
            to="/"
            className="bg-brown-900 border border-brown-900 hover:bg-transparent text-white hover:text-brown-900 w-40 mt-12 px-5 py-4 text-center font-bradford1 tracking-wide"
          >
            View Details
          </Link>
        </div>
      </div>
      <div className="flex flex-col inline-block justify-between w-full md:w-1/2">
        <div className="flex">
          <img
            class="w-full"
            src="https://via.placeholder.com/400x400"
            alt="Modal slider"
          ></img>
        </div>
      </div>
    </div>
  )
}
